import { TUTORIAL_STATE } from 'Config/constants'

import {
  SET_TUTORIAL_STATE,
  SHOW_REQUEST_TUTORIAL_BANNER,
  SHOW_APPROVER_TUTORIAL_BANNER,
  SET_AVAILABLE_TUTORIALS,
  CLEAR_AVAILABLE_TUTORIALS,
} from './types'

const initialState = {
  tutorialState: TUTORIAL_STATE,
  showRequestTutorialBanner: false,
  showApproverTutorialBanner: false,
  availableTutorials: [],
}

const Reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_TUTORIAL_STATE:
      return {
        ...state,
        tutorialState: action.payload,
      }
    case SHOW_REQUEST_TUTORIAL_BANNER:
      return {
        ...state,
        showRequestTutorialBanner: action.payload,
      }
    case SHOW_APPROVER_TUTORIAL_BANNER:
      return {
        ...state,
        showApproverTutorialBanner: action.payload,
      }
    case SET_AVAILABLE_TUTORIALS:
      return {
        ...state,
        availableTutorials: [...state.availableTutorials, ...action.payload],
      }
    case CLEAR_AVAILABLE_TUTORIALS:
      return {
        ...state,
        availableTutorials: action.payload,
      }
    default:
      return state
  }
}

export default Reducer
