import React, { useState, useEffect, useCallback, useRef } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { AuthActions } from 'Redux/auth'
import isEmpty from 'lodash/isEmpty'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import uniqBy from 'lodash/uniqBy'
import cloneDeep from 'lodash/cloneDeep'
import { FETCH_PROVIDERS } from 'Redux/purchases/types'
import { PurchaseTypes, PurchaseActions } from 'Redux/purchases'
import { FilterProviderProducts } from 'Components/molecules'
import { useDebounce } from 'Components/utils/customHooks'
import { SearchTextInput } from 'Components/organisms/productsList'
import { LIMIT_REQUEST_PROVIDERS } from 'Config/constants'
import useTutorialBanner from 'hooks/tutorial/useTutorialBanner'
import { needsApprovalMode } from 'Redux/approver/selectors'

import ProductList, { MultipleProductCardLoader, ResultMessage } from '../productsList'

import { Title, CardsContainer, FilterContainer, TopbarContainer } from './styled'
import { getHabitualSelectedCategories, getSearchHabitualParams, getTitle } from './utils'

const LIMIT_HABITUALS = 10

const HabitualProducts = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { id: paramsId, providerId = '' } = useParams()
  const [products, setProducts] = useState([])
  const [providers, setProviders] = useState([])
  const [lastReference, setLastReference] = useState(null)
  const [filteredProviders, setProvidersFiltered] = useState([])
  const [search, setSearch] = useState('')

  const productsReducer = useSelector(state => state.purchase.habitualProducts)
  const isLoadingProducts = useSelector(state => state.purchase.isLoadingHabitualProducts)
  const categories = useSelector(state => state.purchase.categoriesHabitual)
  const providersReducer = useSelector(state => state.purchase.providers)
  const isLoadingProviders = useSelector(state => state.purchase.isLoadingProviders)
  const providerCategories = useSelector(state => state.purchase.providerCategories)
  const selectedCategories = useSelector(state => state.purchase.selectedCategoriesHabitual)
  const lastReferenceReducer = useSelector(state => state.purchase.lastReference)
  const needsApproval = useSelector(needsApprovalMode)

  const debouncedSearchTerm = useDebounce(search, 300)

  const { isTutorialBannerEnabled, isTutorialEnabled } = useTutorialBanner()

  const observer = useRef()
  const lastProductElementRef = useCallback(
    node => {
      if (isLoadingProducts) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && lastReferenceReducer) {
          setLastReference(lastReferenceReducer)
        }
      })
      if (node) observer.current.observe(node)
    },
    /* eslint-disable-next-line */
    [isLoadingProducts]
  )

  const handleChangeFilters = (filteredId, checkedFilter) => {
    if (checkedFilter) {
      setProvidersFiltered(prevFilters => [...prevFilters, filteredId])
    } else {
      setProvidersFiltered(prevFilters => prevFilters.filter(v => v !== filteredId))
    }
  }

  const handleSuccessUnHabitual = itemId => {
    const newProducts = cloneDeep(products)
    const findIndex = newProducts?.findIndex(v => v.id === itemId)

    if (findIndex > -1) {
      newProducts[findIndex].isHabitual = true
      setProducts([...newProducts])
    }
  }

  const handleSuccessHabitual = itemId => {
    let newProducts = cloneDeep(products)
    newProducts = newProducts?.filter(v => v.id !== itemId)
    setProducts([...newProducts])
  }

  useEffect(() => {
    const params = { limit: LIMIT_REQUEST_PROVIDERS, offset: 0, habituals: true }
    dispatch({ type: FETCH_PROVIDERS, params })
    /* eslint-disable-next-line */
  }, [])

  useEffect(() => {
    setProviders(providersReducer)
  }, [providersReducer])

  useEffect(() => {
    setProducts(prevProducts => uniqBy([...prevProducts, ...productsReducer], 'id'))
    if (productsReducer.length > 0) {
      dispatch(AuthActions.setRefreshingCategory(false))
    }
    /* eslint-disable-next-line */
  }, [productsReducer])

  useEffect(() => {
    setProducts([])
    setLastReference(null)
  }, [paramsId, filteredProviders, debouncedSearchTerm])

  useEffect(() => {
    setProvidersFiltered([])
  }, [paramsId])

  useEffect(() => {
    const categoriesToSelect = providerId ? providerCategories : categories

    const category =
      paramsId === 'all'
        ? { category: { id: 'all', name: t('allCategories') } }
        : getHabitualSelectedCategories(paramsId, categoriesToSelect)

    const searchParams = paramsId === 'all' ? { category: '' } : getSearchHabitualParams(category)

    if (!isEmpty(searchParams)) {
      dispatch(PurchaseActions.setSelectedCategoriesHabitual(category))

      dispatch({
        type: PurchaseTypes.FETCH_HABITUAL_PRODUCTS,
        params: {
          providers: filteredProviders,
          search: debouncedSearchTerm,
          limit: LIMIT_HABITUALS,
          nextReference: lastReference,
          ...searchParams,
        },
      })
    }
    /* eslint-disable-next-line */
  }, [paramsId, debouncedSearchTerm, filteredProviders, lastReference, categories, providerCategories])

  return (
    <>
      <TopbarContainer>
        <Title>{selectedCategories.category ? t('allCategories') : getTitle(selectedCategories)}</Title>
        <FilterContainer>
          <FilterProviderProducts providers={providers} onChangeFilters={handleChangeFilters} />
          <SearchTextInput value={search} onChange={e => setSearch(e.target.value)} />
        </FilterContainer>
      </TopbarContainer>
      {products.length > 0 && (
        <CardsContainer
          $tutorial={isTutorialBannerEnabled && isTutorialEnabled}
          $needsApproval={needsApproval}
        >
          <ProductList
            products={products}
            lastElementRef={lastProductElementRef}
            onSuccessHabitual={handleSuccessHabitual}
            onSuccessUnHabitual={handleSuccessUnHabitual}
          />
        </CardsContainer>
      )}
      {products.length === 0 && !isLoadingProducts && !isLoadingProviders && (
        <ResultMessage isLoadingProducts={isLoadingProducts} />
      )}
      {isLoadingProducts && (
        <CardsContainer $tutorial={isTutorialBannerEnabled && isTutorialEnabled}>
          <MultipleProductCardLoader />
        </CardsContainer>
      )}
    </>
  )
}

export default HabitualProducts
