import React from 'react'

import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { ModalCenter, ModalPendingCarts, NoResults, NoSearch } from 'Components/molecules'
import { ApproverCart, ApproverCartsList } from 'Components/organisms'
import { CartsListLoader } from 'Components/organisms/cartsList'
import { TabItem, Tabs } from 'Components/atoms/Tabs'
import { CART_STATUS } from 'Config/constants'
import { ReactComponent as FilterIcon } from 'Assets/Icons/Filter.svg'
import { ReactComponent as CloseXIcon } from 'Assets/Icons/Cross.svg'
import { ProvidersSearchInput, RequestSearchInput } from 'Components/atoms'
import useApproverCartsTabs from 'hooks/approver/useApproverCartsTabs'
import useApproverCartsPending from 'hooks/approver/useApproverCartsPending'
import useApproverCartsRejected from 'hooks/approver/useApproverCartsRejected'
import useApproverCartsApproved from 'hooks/approver/useApproverCartsApproved'
import useApproverCartsCenterFilter from 'hooks/approver/useApproverCartsCenterFilter'
import useApproverCartsProviderFilter from 'hooks/approver/useApproverCartsProviderFilter'
import useApproverCartsRequestNumberFilter from 'hooks/approver/useApproverCartsRequestNumberFilter'
import { useSelector } from 'react-redux'
import { selectConfig } from 'Redux/auth/utils'
import Summary from 'Components/pages/summary'

import { CenterSelected, Container, FilterCenter, FiltersContainer, Separator } from './styled'

const { PENDING, CLOSE_EXPIRED, APPROVED, REJECTED } = CART_STATUS
const WEEK_SUMMARY = 'weekSummary'

const ApproverCarts = () => {
  const { t } = useTranslation()
  const features = useSelector(selectConfig)

  const {
    TABS,
    onClickTab,
    setShowPendingModal,
    showPendingModal,
    activeTab,
    isFetchingCart,
    requestOrder,
  } = useApproverCartsTabs()

  const isTutorialFlagEnabled = features?.tutorial
  const isTutorialBannerEnabled = useSelector(state => state.tutorial.showApproverTutorialBanner)

  return (
    <>
      <Tabs paddingTop='50px'>
        <Grid container>
          {map(TABS, (value, key) => {
            const className = activeTab === value ? 'active' : ''
            const handleClick = () => onClickTab(value)
            return (
              <Grid item key={key}>
                {(!!features?.weekSummary || key !== WEEK_SUMMARY) && (
                  <TabItem className={className} onClick={handleClick} aria-label={t(key)}>
                    {t(key)}
                  </TabItem>
                )}
              </Grid>
            )
          })}
        </Grid>
      </Tabs>

      <Container $tutorial={isTutorialBannerEnabled && isTutorialFlagEnabled}>
        {activeTab === !TABS.weekSummary && <Filters />}
        {activeTab === TABS.requestsToBeApproved && <PendingApproval />}
        {activeTab === TABS.approvedRequests && <Approved />}
        {activeTab === TABS.rejectedRequests && <Rejected />}
        {activeTab === TABS.weekSummary && <Summary />}
      </Container>

      <ModalPendingCarts showModal={showPendingModal} setShowModal={() => setShowPendingModal(false)} />
      {(!isEmpty(requestOrder) || isFetchingCart) && <ApproverCart />}
    </>
  )
}

const PendingApproval = () => {
  const { t } = useTranslation()
  const { noResults, isFetching, data, isFilterActive } = useApproverCartsPending()
  const { closeToAutoApproved, pendingApproval } = data

  if (isFetching) {
    return <CartsListLoader />
  }

  return (
    <div aria-label={t('approverTutorial.stepFour.target')}>
      {noResults && !isFilterActive && (
        <NoResults title={t('noCartsToBeApprove')} description={t('continueWatching')} />
      )}
      {noResults && isFilterActive && <NoSearch title={t('noSearchTitle')} description={t('noRequestSearchTitles')} />}
      <>
        {!isEmpty(closeToAutoApproved) && (
          <>
            <ApproverCartsList title={t('cartsCloseExpired')} data={closeToAutoApproved} status={CLOSE_EXPIRED} />
            <Separator />
          </>
        )}
        {!isEmpty(pendingApproval) && (
          <ApproverCartsList title={t('cartsPendingApproval')} data={pendingApproval} status={PENDING} />
        )}
      </>
    </div>
  )
}

const Rejected = () => {
  const { t } = useTranslation()
  const { data, isFetching, isFilterActive } = useApproverCartsRejected()

  if (isFetching) {
    return <CartsListLoader />
  }

  return (
    <>
      {isEmpty(data) &&
        (isFilterActive ? (
          <NoSearch title={t('noSearchTitle')} description={t('noRequestSearchTitles')} />
        ) : (
          <NoResults title={t('noCartsRejected')} description={t('continueWatching')} />
        ))}
      {!isEmpty(data) && <ApproverCartsList title={t('cartsRejected')} data={data} status={REJECTED} />}
    </>
  )
}

const Approved = () => {
  const { t } = useTranslation()
  const { data, isFetching, isFilterActive } = useApproverCartsApproved()

  if (isFetching) {
    return <CartsListLoader />
  }

  return (
    <>
      {isEmpty(data) && !isFilterActive && (
        <NoResults title={t('noCartsApproved')} description={t('continueWatching')} />
      )}
      {isEmpty(data) && isFilterActive && (
        <NoSearch title={t('noSearchTitle')} description={t('noRequestSearchTitles')} />
      )}
      {!isEmpty(data) && <ApproverCartsList title={t('cartsApproved')} data={data} status={APPROVED} />}
    </>
  )
}

const Filters = () => (
  <FiltersContainer aria-label='Filtros de solicitudes'>
    <CenterFilter />
    <ProviderFilter />
    <RequestNumberFilter />
  </FiltersContainer>
)

const CenterFilter = () => {
  const { t } = useTranslation()
  const {
    filterCenter,
    showModal,
    onClickShow,
    onClickHide,
    onSelectCenter,
    onResetCenter,
  } = useApproverCartsCenterFilter()

  return (
    <>
      {filterCenter ? (
        <CenterSelected onClick={onResetCenter}>
          {filterCenter.name} <CloseXIcon className='icon' />
        </CenterSelected>
      ) : (
        <FilterCenter onClick={onClickShow}>
          <FilterIcon className='icon' fill='green' />
          {t('filterCenter')}
        </FilterCenter>
      )}
      <ModalCenter
        showModal={showModal}
        setShowModal={onClickHide}
        onSelectCenter={onSelectCenter}
        showSubtitle={false}
        title='filterCenter'
      />
    </>
  )
}

const ProviderFilter = () => {
  const { value, onChange } = useApproverCartsProviderFilter()

  return <ProvidersSearchInput value={value} onChange={onChange} />
}

const RequestNumberFilter = () => {
  const { onChange } = useApproverCartsRequestNumberFilter()

  return <RequestSearchInput onChange={onChange} />
}

export default ApproverCarts
