import styled from 'styled-components'
import colors from 'Assets/colors'

export const Container = styled.div`
  padding: 2.5rem 1.25rem 1rem;
  height: calc(100vh - 64px);
  overflow: auto;
`

export const SingleTicketContainer = styled.div`
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  margin-bottom: 24px;
  height: 100%;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
`

export const SingleTicketHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${colors.black};
  border-radius: 8px 8px 0px 0px;
  padding: 8px 16px;
`

export const SingleTicketInfo = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.white};
`

export const SingleTicketContent = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 34%;
  border-bottom: 1px solid rgba(51, 51, 51, 0.24);
  min-height: 105px;
`

export const ProviderInfoContainer = styled.div`
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ProviderName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 8px;
`

export const TicketInfoAuxText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray40l};
`

export const TicketInfoContainer = styled.div`
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TicketStatusContainer = styled.div`
  padding: 12px 16px;
  align-items: space-between;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const SingleTicketMainText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 8px;
`

export const TicketStatusCombo = styled.div`
  display: flex;
  padding: 4px 0;
`

export const TicketStatusInfoContainer = styled.div`
  margin-left: 16px;
`

export const SingleTicketConversationContainer = styled.div`
  background: ${colors.gray98b};
  height: calc(100% - 201px);
  display: block;
  border-radius: 0 0 8px 8px;
  overflow-y: auto;
`

export const StaticSingleTicketInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-bottom: 1px solid rgba(51, 51, 51, 0.24);
  padding: 16px 0;

  &:first-child {
    .comment {
      border-bottom: 1px solid rgba(51, 51, 51, 0.24);
      background-color: ${colors.white};
      .zd-comment {
        > p {
          border-bottom: 1px solid rgba(51, 51, 51, 0.24);
        }
      }
    }
  }

  &:last-child {
    border-bottom: none;
  }
`

export const StaticSingleTicketInfoTop = styled.div`
  display: flex;
  flex: 1;
  padding: 0 16px;
`

export const StaticSingleTicketUserName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 8px;
  padding-right: 20px;
  display: flex;
  flex: 1;
`

export const StaticSingleTicketComment = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};

  .zd-comment {
    padding: 0 16px;
    p {
      background: ${colors.gray98b};
      margin-right: -16px;
      margin-left: -16px;
      padding: 0 16px;
    }
  }
`

export const StaticSingleTicketCreated = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.gray40l};
  text-align: right;
`

export const SingleTicketAttachmentsContainer = styled.div`
  padding: 16px 16px 0;
`

export const SingleTicketAttachment = styled.div`
  border: 1px solid ${colors.black};
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  svg {
    margin-right: 18px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

export const SingleTicketAttachmentWithDelete = styled.div`
  border: 1px solid ${colors.black};
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  justify-content: space-between;
  align-items: center;
  svg {
    margin-right: 18px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  > div {
    display: flex;
    align-items: center;
  }
`

export const TicketSendForm = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
`

export const TicketSendFormActions = styled.form`
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(51, 51, 51, 0.24);
  background: ${colors.white};
`

export const AttachButtonContainer = styled.label`
  width: 56px;
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  cursor: pointer;
  text-align: center;
`

export const SendButtonContainer = styled.button`
  background-color: ${props => (!props.value ? colors.gray40l : colors.secondary)};
  width: 44px;
  height: 32px;
  border-radius: 8px;
  margin-right: 16px;
  cursor: pointer;
  padding: 7px;
  svg {
    fill: ${colors.white};
  }
`

export const SingleTicketAttachmentSelf = styled.div`
  padding: 0 16px 12px;
  background: ${colors.white};
`

export const TrashButtonContainer = styled.button`
  background-color: ${colors.white};
  width: 44px;
  height: 32px;
  cursor: pointer;
  svg {
    margin-right: 0;
  }
`
