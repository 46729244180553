import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import find from 'lodash/find'
import { getCart } from 'Services/api'
import { CartDrawer } from 'Components/organisms'
import { ReactComponent as RemoveRequest } from 'Assets/Icons/RemoveRequest.svg'
import { PurchaseTypes } from 'Redux/purchases'
import { HistoryRequestProduct, ModalLoading, ModalConfirmation, ModalRepeatOrderErrors } from 'Components/molecules'
import useTutorialBanner from 'hooks/tutorial/useTutorialBanner'
import { checkConfirmedCart } from 'Redux/purchases/actions'

import {
  Container,
  Header,
  Title,
  Grow,
  RequestHeader,
  RequestHeaderInfo,
  RequestsContainer,
  SingleRequestContainer,
} from './styled'

const actionTypes = {
  addToCart: 'ADD_TO_CART',
  remove: 'REMOVE',
}

const FailedRequests = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cart = useSelector(state => state.purchase.cart)
  const orders = useSelector(({ purchase }) => purchase.orders)
  const centerId = useSelector(state => state.auth.selectedCenter.id)
  const isSendingCart = useSelector(s => s.lists.isSendingCart)
  const [showDrawer, setShowDrawer] = useState(false)
  const [showModal, setShowModal] = useState(null)
  const [requestIdSelected, setRequestIdSelected] = useState(null)
  const [disabledBtns, setDisabledBtns] = useState(false)

  const { isTutorialBannerEnabled, isTutorialEnabled } = useTutorialBanner()

  useEffect(() => {
    dispatch({
      type: PurchaseTypes.FETCH_ORDERS,
      params: {
        endDate: moment().add(2, 'day').startOf('day').format(),
        startDate: moment().subtract(1, 'year').startOf('day').format(),
        statuses: ['Error', 'Error Corregido'],
      },
    })
  }, [dispatch])

  useEffect(() => {
    dispatch({ type: PurchaseTypes.FETCH_CART })
  }, [centerId, dispatch])

  useEffect(() => {
    getCart(centerId)
      .then(res => {
        dispatch(checkConfirmedCart(res))
        setDisabledBtns(!!res.errorRelatedId)
      })
      .catch(() => setDisabledBtns(false))
  }, [centerId, cart, dispatch])

  const handleCloseModal = () => {
    setRequestIdSelected(null)
    setShowModal(null)
  }

  const handleOpenModal = (id, type) => {
    setRequestIdSelected(id)
    switch (type) {
      case 'remove':
        setShowModal(actionTypes.remove)
        break
      case 'addToCart':
        setShowModal(actionTypes.addToCart)
        break
      default:
        break
    }
  }

  const onRemoveRequest = () => {
    setShowModal(null)
    dispatch({
      type: PurchaseTypes.REMOVE_REQUEST_FAILED,
      payload: {
        id: requestIdSelected,
      },
    })
    setRequestIdSelected(null)
  }

  const onAddRequest = () => {
    setShowModal(null)

    const order = find(orders, { id: requestIdSelected })
    if (order?.id) {
      dispatch({
        type: PurchaseTypes.ADD_REQUEST_FAILED_TO_CART,
        payload: order,
      })
    }

    setRequestIdSelected(null)
  }

  return (
    <>
      <Container $tutorial={isTutorialBannerEnabled && isTutorialEnabled}>
        <Header>
          <Title>{t('failedRequests.titleSection')}</Title>
          <Grow />
        </Header>
        {orders &&
          orders.map(order => (
            <SingleRequestContainer key={order.id}>
              <RequestHeader>
                {order.id ? (
                  <RequestHeaderInfo>{t('request', { request: order.id })}</RequestHeaderInfo>
                ) : (
                  <div>&nbsp;</div>
                )}
                <RequestHeaderInfo>{moment(order.createdAt).format('DD/MM/YYYY')}</RequestHeaderInfo>
              </RequestHeader>
              <HistoryRequestProduct
                onAction={handleOpenModal}
                order={order}
                centerId={centerId}
                disabledBtns={disabledBtns}
              />
            </SingleRequestContainer>
          ))}
        <RequestsContainer />
        <CartDrawer showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
      </Container>
      <ModalLoading showModal={isSendingCart} />
      <ModalConfirmation
        showModal={showModal === actionTypes.remove}
        setShowModal={handleCloseModal}
        onCancel={handleCloseModal}
        onAccept={onRemoveRequest}
        color='red'
        titleColor='black'
        title={t('failedRequests.removeRequestTitleModal')}
        message={t('failedRequests.removeRequestMessageModal')}
        action={t('failedRequests.removeRequestConfirmBtn')}
        cancelAction={t('cancelButton')}
        icon={<RemoveRequest />}
        height='560px'
        width='680px'
      />
      <ModalConfirmation
        showModal={showModal === actionTypes.addToCart}
        setShowModal={handleCloseModal}
        onCancel={handleCloseModal}
        onAccept={onAddRequest}
        color='secondary'
        titleColor='black'
        title={t('failedRequests.addRequestToCartTitleModal')}
        message={t('failedRequests.addRequestToCartMessage')}
        action={t('failedRequests.addRequestToCartConfirmBtn')}
        cancelAction={t('cancel')}
        height='240px'
        width='680px'
      />
      <ModalRepeatOrderErrors />
    </>
  )
}

export default FailedRequests
