import {
  CHECK_TUTORIAL,
  CHECK_TUTORIAL_BANNER,
  FINISH_TUTORIAL,
  LAUNCH_TUTORIAL,
  SET_TUTORIAL_STATE,
  SHOW_REQUEST_TUTORIAL_BANNER,
  SHOW_APPROVER_TUTORIAL_BANNER,
  SET_AVAILABLE_TUTORIALS,
  CLEAR_AVAILABLE_TUTORIALS,
} from './types'

export const launchTutorial = tutorial => ({
  type: LAUNCH_TUTORIAL,
  payload: tutorial,
})

export const finishTutorial = tutorial => ({
  type: FINISH_TUTORIAL,
  payload: tutorial,
})

export const checkTutorial = tutorial => ({
  type: CHECK_TUTORIAL,
  payload: tutorial,
})

export const setTutorialState = tutorialState => ({
  type: SET_TUTORIAL_STATE,
  payload: tutorialState,
})

export const checkTutorialBanner = tutorial => ({
  type: CHECK_TUTORIAL_BANNER,
  payload: tutorial,
})

export const showRequestTutorialBanner = showTutorial => ({
  type: SHOW_REQUEST_TUTORIAL_BANNER,
  payload: showTutorial,
})

export const showApproverTutorialBanner = showTutorial => ({
  type: SHOW_APPROVER_TUTORIAL_BANNER,
  payload: showTutorial,
})

export const setAvailableTutorials = tutorial => ({
  type: SET_AVAILABLE_TUTORIALS,
  payload: tutorial,
})

export const clearAvailableTutorials = tutorials => ({
  type: CLEAR_AVAILABLE_TUTORIALS,
  paylaod: tutorials,
})
