import { all, call, put, takeLeading, select, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import i18next from 'i18next'
import { getCenters } from 'Services/api'
import * as Sentry from 'Services/sentry'
import { setCenter } from 'Redux/auth/actions'
import { getTutorials, updateTutorial } from 'Services/api/tutorials'
import {
  TUTORIAL_MODE,
  TUTORIAL_NAMES,
  TUTORIAL_REQUEST_CENTER,
  TUTORIAL_SHOW_BANNER_DURATION,
  TUTORIAL_STATUS,
} from 'Config/constants'
import { toast } from 'react-toastify'
import { dateComparator } from 'Config/utils'

import { CHECK_TUTORIAL, CHECK_TUTORIAL_BANNER, FINISH_TUTORIAL, LAUNCH_TUTORIAL } from './types'
import {
  launchTutorial,
  setTutorialState,
  showRequestTutorialBanner,
  showApproverTutorialBanner,
  setAvailableTutorials,
  clearAvailableTutorials,
} from './actions'

export function* launchTutorialSaga(action) {
  try {
    const { tutorial, callback } = action.payload
    const prevSelectedCenterId = yield select(state => state.auth.selectedCenter.id)
    const tutorialState = yield select(state => state.tutorial.tutorialState)

    sessionStorage.setItem('mode', TUTORIAL_MODE)
    sessionStorage.setItem('tutorial', tutorial)

    if (TUTORIAL_REQUEST_CENTER !== prevSelectedCenterId && tutorial === TUTORIAL_NAMES.request) {
      sessionStorage.setItem('prevCenter', prevSelectedCenterId)
    }

    if (tutorial !== TUTORIAL_NAMES.approverRole) {
      const { data: centerList = [] } = yield call(getCenters)
      const selectedCenter = centerList.find(center => center.id === TUTORIAL_REQUEST_CENTER)
      yield put(setCenter(selectedCenter))
    }

    yield call(updateTutorial, {
      name: tutorial,
      body: { done: TUTORIAL_STATUS.pending },
    })

    yield put(
      setTutorialState({
        ...tutorialState,
        tourActive: tutorial,
        run: true,
        restart: false,
      })
    )
    if (callback) callback()
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
    }
    sessionStorage.removeItem('mode')
    sessionStorage.removeItem('tutorial')
    toast.error(i18next.t('tutorialError'))
  }
}

export function* finishTutorialSaga(action) {
  try {
    const { tutorial, callback } = action.payload

    const tutorialState = yield select(state => state.tutorial.tutorialState)
    const availableTutorials = yield select(state => state.tutorial.availableTutorials)

    yield put(
      setTutorialState({
        ...tutorialState,
        tourActive: '',
        run: false,
        stepIndex: 0,
      })
    )
    yield call(updateTutorial, {
      name: tutorial,
      body: { done: TUTORIAL_STATUS.done },
    })

    const updatedTutorial = availableTutorials.map(t => ({ ...t, done: t.name === tutorial ? '1' : t.done }))
    yield put(clearAvailableTutorials(updatedTutorial))

    if (tutorial === TUTORIAL_NAMES.approverRole) {
      yield put(setCenter({}))
    }
    sessionStorage.removeItem('mode')
    sessionStorage.removeItem('tutorial')

    if (tutorial === TUTORIAL_NAMES.request) {
      const { data: centerList = [] } = yield call(getCenters)
      const prevCenterId = sessionStorage.getItem('prevCenter')
      const prevCenter = centerList.find(center => center.id === prevCenterId)
      const redirectCenter = prevCenter || {}
      yield put(setCenter(redirectCenter))
    }

    if (callback) callback()
  } catch (e) {
    if (!axios.isCancel(e)) {
      Sentry.captureException(e)
    }
    sessionStorage.removeItem('mode')
    sessionStorage.removeItem('tutorial')
    toast.error(i18next.t('tutorialError'))
  }
}

function* checkTutorialSaga(action) {
  try {
    const { tutorial, callback } = action.payload
    const isTutorialPending = false
    const isTutorialActive = false

    if (isTutorialPending || isTutorialActive) {
      yield put(launchTutorial({ tutorial, callback }))
      return
    }

    sessionStorage.removeItem('mode')
    sessionStorage.removeItem('tutorial')
  } catch (error) {
    // eslint-disable-next-line no-console
    if (!axios.isCancel(error)) {
      Sentry.captureException(error)
    }
    sessionStorage.removeItem('mode')
    sessionStorage.removeItem('tutorial')
    toast.error(i18next.t('tutorialError'))
  }
}

function* checkTutorialBannerSaga(action) {
  try {
    const tutorial = action.payload
    const availableTutorials = yield call(getTutorials, { name: tutorial })
    yield put(setAvailableTutorials(availableTutorials))

    const selectedTutorial = availableTutorials?.find(elem => elem?.name === tutorial)

    if (!selectedTutorial) {
      sessionStorage.removeItem('mode')
      sessionStorage.removeItem('tutorial')
      return
    }

    const showTutorialBannerSaga = dateComparator({
      firstDate: selectedTutorial?.created,
      daysBetween: TUTORIAL_SHOW_BANNER_DURATION,
    })

    if (tutorial === TUTORIAL_NAMES.request) {
      yield put(showRequestTutorialBanner(showTutorialBannerSaga))
      return
    }

    if (tutorial === TUTORIAL_NAMES.approverRole) {
      yield put(showApproverTutorialBanner(showTutorialBannerSaga))
      return
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      Sentry.captureException(error)
    }
    sessionStorage.removeItem('mode')
    sessionStorage.removeItem('tutorial')
  }
}

function* watchLaunchTutorialSaga() {
  yield takeLeading(LAUNCH_TUTORIAL, launchTutorialSaga)
}

function* watchCheckTutorialSaga() {
  yield takeEvery(CHECK_TUTORIAL, checkTutorialSaga)
}

function* watchFinishTutorialSaga() {
  yield takeLeading(FINISH_TUTORIAL, finishTutorialSaga)
}

function* watchCheckTutorialBannerSaga() {
  yield takeEvery(CHECK_TUTORIAL_BANNER, checkTutorialBannerSaga)
}

export default function* rootTutorialSaga() {
  yield all([
    watchLaunchTutorialSaga(),
    watchCheckTutorialSaga(),
    watchFinishTutorialSaga(),
    watchCheckTutorialBannerSaga(),
  ])
}
