import styled from 'styled-components'
import colors from 'Assets/colors'

export const Container = styled.div`
  max-height: ${props => props.maxHeight};
`

export const Topbar = styled.div`
  height: 48px;
  padding: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: ${colors.black};
  background: ${colors.grayInput};
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const RestartButton = styled.button`
  font-size: 1rem;
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
`
