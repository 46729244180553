import React, { useEffect, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { ModalLoading, NoResults, NoSearch } from 'Components/molecules'
import { CartDrawer, CartsList, SearchBar } from 'Components/organisms'
import { CartsListLoader } from 'Components/organisms/cartsList'
import { TabItem, Tabs } from 'Components/atoms/Tabs'
import { ReactComponent as CloseXIcon } from 'Assets/Icons/Cross.svg'
import { CART_STATUS } from 'Config/constants'
import { ProvidersSearchInput, RequestSearchInput } from 'Components/atoms'
import useCartsTabs from 'hooks/carts/useCartsTabs'
import useCartsPending from 'hooks/carts/useCartsPending'
import useCartsRejected from 'hooks/carts/useCartsRejected'
import useCartsApproved from 'hooks/carts/useCartsApproved'
import useCartsCancelled from 'hooks/carts/useCartsCancelled'
import useCartsProviderFilter from 'hooks/carts/useCartsProviderFilter'
import useCartsRequestNumberFilter from 'hooks/carts/useCartsRequestNumberFilter'
import { useSelector } from 'react-redux'
import { selectConfig } from 'Redux/auth/utils'

import { Container, FiltersContainer, FilterSelected, FiltersMasterContainer } from './styled'

const { PENDING, CANCELLED, APPROVED, REJECTED } = CART_STATUS

const Carts = () => {
  const { t } = useTranslation()
  const { TABS, activeTab, onClickTab, filterRequestNumber, queryId, isUpdatingCart } = useCartsTabs()
  const [showDrawer, setShowDrawer] = useState(false)

  const center = useSelector(({ auth }) => auth.selectedCenter)
  const features = useSelector(selectConfig)

  const isTutorialFlagEnabled = features?.tutorial
  const isTutorialEnabled = isTutorialFlagEnabled && center?.requiresApproval
  const isTutorialBannerEnabled = useSelector(state => state.tutorial.showRequestTutorialBanner)
  const tutorialState = useSelector(state => state.tutorial.tutorialState)

  useEffect(() => {
    if (isTutorialEnabled && tutorialState.stepIndex === 9) {
      onClickTab(TABS.approvedRequests)
    }

    if (isTutorialEnabled && tutorialState.stepIndex === 12) {
      onClickTab(TABS.rejectedRequests)
    }

    if (isTutorialEnabled && tutorialState.stepIndex === 13) {
      onClickTab(TABS.cancelledRequests)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tutorialState.stepIndex])

  useEffect(() => {
    if (isTutorialEnabled && tutorialState.stepIndex === 8 && activeTab === TABS.approvedRequests) {
      onClickTab(TABS.requestsToBeApproved)
    }

    if (isTutorialEnabled && tutorialState.stepIndex === 11 && activeTab === TABS.rejectedRequests) {
      onClickTab(TABS.approvedRequests)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tutorialState.stepIndex])

  return (
    <>
      <SearchBar setShowDrawer={setShowDrawer} />
      <Tabs paddingTop='20px'>
        <Grid container>
          {map(TABS, (tab, key) => {
            const className = activeTab === tab ? 'active' : ''
            const handleClick = () => onClickTab(tab)
            return (
              <Grid item key={key}>
                <TabItem className={className} onClick={handleClick} aria-label={t(`request-cart-${key}`)}>
                  {t(key)}
                </TabItem>
              </Grid>
            )
          })}
        </Grid>
      </Tabs>
      <Container $tutorial={isTutorialBannerEnabled && isTutorialEnabled}>
        <FiltersMasterContainer>
          {filterRequestNumber && queryId ? (
            <RequestSelected />
          ) : (
            <FiltersContainer>
              <ProviderFilter />
              <RequestNumberFilter />
            </FiltersContainer>
          )}
        </FiltersMasterContainer>
        {activeTab === TABS.requestsToBeApproved && <PendingApproval />}
        {activeTab === TABS.approvedRequests && <Approved />}
        {activeTab === TABS.rejectedRequests && <Rejected />}
        {activeTab === TABS.cancelledRequests && <Cancelled />}
      </Container>
      <CartDrawer showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
      <ModalLoading showModal={isUpdatingCart} />
    </>
  )
}

const PendingApproval = () => {
  const { t } = useTranslation()
  const { isFetching, data, isFilterActive } = useCartsPending()

  if (isFetching) {
    return <CartsListLoader />
  }

  if (isEmpty(data) && !isFilterActive) {
    return <NoResults title={t('noCartsToBeApprove')} description={t('continueWatching')} />
  }

  if (isEmpty(data) && isFilterActive) {
    return <NoSearch title={t('noSearchTitle')} description={t('noRequestSearchTitles')} />
  }

  return <CartsList data={data} status={PENDING} />
}

const Rejected = () => {
  const { t } = useTranslation()
  const { isFetching, data, isFilterActive } = useCartsRejected()

  if (isFetching) {
    return <CartsListLoader />
  }

  if (isEmpty(data) && !isFilterActive) {
    return <NoResults title={t('noCartsRejected')} description={t('continueWatching')} />
  }

  if (isEmpty(data) && isFilterActive) {
    return <NoSearch title={t('noSearchTitle')} description={t('noRequestSearchTitles')} />
  }

  return <CartsList title={t('cartsRejected')} data={data} status={REJECTED} />
}

const Approved = () => {
  const { t } = useTranslation()
  const { isFetching, data, isFilterActive } = useCartsApproved()

  if (isFetching) {
    return <CartsListLoader />
  }

  if (isEmpty(data) && !isFilterActive) {
    return <NoResults title={t('noCartsApproved')} description={t('continueWatching')} />
  }

  if (isEmpty(data) && isFilterActive) {
    return <NoSearch title={t('noSearchTitle')} description={t('noRequestSearchTitles')} />
  }

  return <CartsList title={t('cartsApproved')} data={data} status={APPROVED} />
}

const Cancelled = () => {
  const { t } = useTranslation()
  const { isFetching, data, isFilterActive } = useCartsCancelled()

  if (isFetching) {
    return <CartsListLoader />
  }

  if (isEmpty(data) && !isFilterActive) {
    return <NoResults title={t('noCartsCancelled')} description={t('continueWatching')} />
  }

  if (isEmpty(data) && isFilterActive) {
    return <NoSearch title={t('noSearchTitle')} description={t('noRequestSearchTitles')} />
  }

  return <CartsList title={t('cartsCancelled')} data={data} status={CANCELLED} />
}

const ProviderFilter = () => {
  const { value, onChange } = useCartsProviderFilter()

  return <ProvidersSearchInput value={value} onChange={onChange} isMulti={false} />
}

const RequestNumberFilter = () => {
  const { onChange } = useCartsRequestNumberFilter()

  return <RequestSearchInput onChange={onChange} />
}

const RequestSelected = () => {
  const location = useLocation()
  const history = useHistory()

  const { t } = useTranslation()

  const { value, onChange } = useCartsRequestNumberFilter()

  const handleClick = () => {
    const queryParams = new URLSearchParams(location.search)
    if (!queryParams.has('id')) return
    queryParams.delete('id')
    history.replace({
      search: queryParams.toString(),
    })
    onChange('')
  }

  return (
    <FilterSelected onClick={handleClick}>
      {t('request', { request: value })} <CloseXIcon className='icon' />
    </FilterSelected>
  )
}

export default Carts
