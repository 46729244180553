import { selectConfig } from 'Redux/auth/utils'
import { useSelector } from 'react-redux'

const useTutorialBanner = () => {
  const center = useSelector(({ auth }) => auth.selectedCenter)
  const features = useSelector(selectConfig)
  const isTutorialFlagEnabled = features?.tutorial
  const isTutorialEnabled = isTutorialFlagEnabled && center?.requiresApproval
  const isTutorialBannerEnabled = useSelector(state => state.tutorial.showRequestTutorialBanner)

  return { isTutorialEnabled, isTutorialBannerEnabled }
}

export default useTutorialBanner
