import React, { useEffect, useState, useMemo } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import _ from 'lodash'
import { PurchaseTypes, PurchaseActions } from 'Redux/purchases'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CategoryButton, CategoryHabitualButton } from 'Components/atoms'
import routes from 'Config/routes'
import { needsApprovalMode } from 'Redux/approver/selectors'
import useTutorialBanner from 'hooks/tutorial/useTutorialBanner'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { selectConfig } from 'Redux/auth/utils'

import { SectionContainer, ProviderName } from './styled'

const Siderbar = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { providerId } = useParams()
  const { pathname } = useLocation()

  const needsApproval = useSelector(needsApprovalMode)

  const { isTutorialBannerEnabled, isTutorialEnabled } = useTutorialBanner()

  useEffect(() => {
    if (providerId) dispatch({ type: PurchaseTypes.FETCH_CATEGORIES_PROVIDERS, params: { providerId } })
  }, [dispatch, providerId])

  const isProviderRoute = _.get(history, 'location.pathname', '').includes(routes.providers)
  const isHabitualRoute = _.get(history, 'location.pathname', '').includes(routes.habitualCats)

  const navigate = id => {
    dispatch(PurchaseActions.setShowOnlyOneProducts(false))
    if (isProviderRoute) {
      history.push(routes.addCategoryProvider(providerId, id))
      return
    }
    if (isHabitualRoute) {
      history.push(routes.AddCategoryHabitual(id))
      return
    }
    history.push(routes.AddCategory(id))
  }

  const hidden = useSelector(state => state.auth.hiddenNavbar)
  const categoriesMain = useSelector(state => state.purchase.categories)
  const categoriesHabitual = useSelector(state => state.purchase.categoriesHabitual)
  const providerCategories = useSelector(state => state.purchase.providerCategories)
  const isLoading = useSelector(state => state.purchase.isLoadingCategories)
  const provider = useSelector(state => {
    if (!isProviderRoute) return {}
    // eslint-disable-next-line
    return state.purchase.providers.find(({ id }) => id == providerId) || {}
  })
  const features = useSelector(selectConfig)

  const [selected, setSelected] = useState(0)

  useEffect(() => {
    if (!isHabitualRoute) return
    const routeIndex = categoriesHabitual.findIndex(cat => routes.AddCategoryHabitual(cat.id) === pathname)
    setSelected(routeIndex)
  }, [categoriesHabitual, pathname, isHabitualRoute])

  useEffect(() => {
    dispatch({ type: PurchaseTypes.FETCH_CATEGORIES_HABITUAL })
    if (!isHabitualRoute) {
      setSelected(0)
    }
  }, [isHabitualRoute, dispatch, features])

  let categories
  if (isProviderRoute) categories = providerCategories
  else if (isHabitualRoute) categories = categoriesHabitual
  else categories = categoriesMain

  const filteredCategories = useMemo(() => categories.filter(({ id }) => id !== 'NA'), [categories])

  return (
    <SectionContainer
      opacity={isLoading ? 0.5 : 1}
      isHidden={hidden}
      needsApproval={needsApproval}
      $tutorial={isTutorialEnabled && isTutorialBannerEnabled}
    >
      {isProviderRoute ? <ProviderName>{provider.name}</ProviderName> : null}
      {isLoading ? <CircularProgress style={{ position: 'absolute', top: '8rem', left: '43%' }} /> : null}
      {!isHabitualRoute
        ? filteredCategories.map(({ children, id, name }) => (
            <CategoryButton subCategories={children} key={id} id={id} name={name} navigate={navigate} />
          ))
        : null}
      {isHabitualRoute
        ? filteredCategories.map(({ id, name }, index) => (
            <CategoryHabitualButton
              key={id}
              id={id}
              name={name}
              navigate={navigate}
              onClick={() => setSelected(index)}
              selected={index === selected || false}
            />
          ))
        : null}
    </SectionContainer>
  )
}

export default Siderbar
