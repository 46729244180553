import React from 'react'

import { useTranslation } from 'react-i18next'

import { Topbar, RestartButton } from './styled'

const ApproverTutorialBanner = () => {
  const { t } = useTranslation()

  const handleClick = () => {
    window.location.href = process.env.REACT_APP_APPROVER_TUTORIAL_URL
  }

  return (
    <Topbar>
      <p>
        {t('newFunctionality')} <strong>{t('monitorRequest')}</strong>
        {t('nowApprovalApproverRequests')}
      </p>
      <RestartButton type='button' onClick={handleClick}>
        <strong>{t('howItWorks')}</strong>
      </RestartButton>
    </Topbar>
  )
}

export default ApproverTutorialBanner
