import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowDown } from 'Assets/Icons/Arrow-Down-complete.svg'

import { Container, RedSquare } from './styled'

const ProductsNotAvailable = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <RedSquare>
        {t('someProductsNotAvailable')}
        <ArrowDown onClick={onClick} cursor='pointer' />
      </RedSquare>
    </Container>
  )
}

ProductsNotAvailable.propTypes = {
  onClick: PropTypes.func,
}

ProductsNotAvailable.defaultProps = {
  onClick: () => null,
}

export default ProductsNotAvailable
