import React, { useState, useRef } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as FilterLeftIcon } from 'Assets/Icons/FilterLeft.svg'
import { useClickOutside } from 'Components/utils/customHooks'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

import { sortTypes, orderTypes } from './constants'
import { FilterButton, FilterContainer } from './styled'

const Order = ({ orderAndSort, setOrderAndSort }) => {
  const filterRef = useRef()
  const [displayFilter, setDisplayFilter] = useState('none')
  const closeFilters = () => setDisplayFilter('none')
  useClickOutside(filterRef, closeFilters)
  const { t } = useTranslation()

  const { order, sort } = orderAndSort

  const isCheaperWeight = order === orderTypes.ascending && sort === sortTypes.weight
  const isCheaper = order === orderTypes.ascending && sort === sortTypes.normal
  const isMoreExpensiveWeight = order === orderTypes.descending && sort === sortTypes.weight
  const isMoreExpensive = order === orderTypes.descending && sort === sortTypes.normal
  const isAlphabeticalAtoZ = order === orderTypes.ascending && sort === sortTypes.name
  const isAlphabeticalZtoA = order === orderTypes.descending && sort === sortTypes.name
  const isAny =
    !isCheaperWeight &&
    !isCheaper &&
    !isMoreExpensiveWeight &&
    !isMoreExpensive &&
    !isAlphabeticalAtoZ &&
    !isAlphabeticalZtoA

  return (
    <div ref={filterRef} style={{ position: 'relative' }}>
      <FilterButton onClick={() => setDisplayFilter(value => (value === 'none' ? 'flex' : 'none'))}>
        <FilterLeftIcon className='icon' />
        {t('arrangeProducts')}
      </FilterButton>
      <FilterContainer display={displayFilter}>
        <FormControlLabel
          value='start'
          control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
          label='Predeterminado'
          labelPlacement='start'
          checked={isAny}
          onClick={() => setOrderAndSort({ order: '', sort: '' })}
        />
        <FormControlLabel
          value='start'
          control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
          label='€/KG más barato'
          labelPlacement='start'
          checked={isCheaperWeight}
          onClick={() => setOrderAndSort({ order: orderTypes.ascending, sort: sortTypes.weight })}
        />
        <FormControlLabel
          value='start'
          control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
          label='€/KG más caro'
          labelPlacement='start'
          checked={isMoreExpensiveWeight}
          onClick={() => setOrderAndSort({ order: orderTypes.descending, sort: sortTypes.weight })}
        />
        <FormControlLabel
          value='start'
          control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
          label='Precio más barato'
          labelPlacement='start'
          checked={isCheaper}
          onClick={() => setOrderAndSort({ order: orderTypes.ascending, sort: sortTypes.normal })}
        />
        <FormControlLabel
          value='start'
          control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
          label='Precio más caro'
          labelPlacement='start'
          checked={isMoreExpensive}
          onClick={() => setOrderAndSort({ order: orderTypes.descending, sort: sortTypes.normal })}
        />
      </FilterContainer>
    </div>
  )
}

Order.defaultProps = {
  orderAndSort: {},
  setOrderAndSort: () => {},
}

Order.propTypes = {
  orderAndSort: PropTypes.object,
  setOrderAndSort: PropTypes.func,
}

export default Order
