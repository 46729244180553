export const FETCH_AUTOCOMPLETE_CATEGORIES_AND_PROVIDERS =
  'portal_compras/purchases/FETCH_AUTOCOMPLETE_CATEGORIES_AND_PROVIDERS'
export const FETCH_AUTOCOMPLETE_PROVIDERS = 'portal_compras/purchases/FETCH_AUTOCOMPLETE_PROVIDERS'
export const SEARCH_PURCHASES = 'portal_compras/purchases/SEARCH_PURCHASES'
export const ERROR_SEARCH = 'portal_compras/purchases/ERROR_SEARCH'
export const ERROR_PRODUCTS = 'portal_compras/purchases/ERROR_PRODUCTS'
export const FETCH_PRODUCTS = 'portal_compras/purchases/FETCH_PRODUCTS'
export const CART_CONFIRMED = 'portal_compras/purchases/CART_CONFIRMED'
export const SET_PRODUCTS = 'portal_compras/purchases/SET_PRODUCTS'
export const SET_PRODUCTS_COUNT = 'portal_compras/purchases/SET_PRODUCTS_COUNT'
export const SET_SELECTED_CATEGORIES = 'portal_compras/purchases/SET_SELECTED_CATEGORIES'
export const IS_LOADING_PRODUCTS = 'portal_compras/purchases/IS_LOADING_PRODUCTS'
export const FETCH_CATEGORIES = 'portal_compras/purchases/FETCH_CATEGORIES'
export const SET_CATEGORIES = 'portal_compras/purchases/SET_CATEGORIES'
export const IS_LOADING_CATEGORIES = 'portal_compras/purchases/IS_LOADING_CATEGORIES'
export const ERROR_CATEGORIES = 'portal_compras/purchases/ERROR_CATEGORIES'
export const FETCH_PROVIDERS = 'portal_compras/purchases/FETCH_PROVIDERS'
export const SET_PROVIDERS = 'portal_compras/purchases/SET_PROVIDERS'
export const SET_ERROR_PROVIDERS = 'portal_compras/purchases/SET_ERROR_PROVIDERS'
export const IS_LOADING_PROVIDERS = 'portal_compras/purchases/IS_LOADING_PROVIDERS'
export const FETCH_CATEGORIES_PROVIDERS = 'portal_compras/purchases/FETCH_CATEGORIES_PROVIDERS'
export const SET_PROVIDER_CATEGORIES = 'portal_compras/purchases/SET_PROVIDER_CATEGORIES'
export const EDIT_CART = 'portal_compras/purchases/EDIT_CART'
export const SET_IS_EDITING_CART = 'portal_compras/purchases/SET_IS_EDITING_CART'
export const SET_LOADING_PRODUCT = 'portal_compras/purchases/SET_LOADING_PRODUCT'
export const FETCH_PRODUCT = 'portal_compras/purchases/FETCH_PRODUCT'
export const SET_PRODUCT = 'portal_compras/purchases/SET_PRODUCT'
export const CART_INITIATED = 'portal_compras/purchases/CART_INITIATED'
export const SET_CART = 'portal_compras/purchases/SET_CART'
export const FETCH_CART = 'portal_compras/purchases/FETCH_CART'
export const PRE_CONFIRM_CART = 'portal_compras/purchases/PRE_CONFIRM_CART'
export const ADD_PRODUCT_TO_CART = 'portal_compras/purchases/ADD_PRODUCT_TO_CART'
export const NEXT_ADD_PRODUCT_TO_CART = 'portal_compras/purchases/NEXT_ADD_PRODUCT_TO_CART'
export const EMPTY_CART = 'portal_compras/purchases/EMPTY_CART'
export const FETCH_BUDGET = 'portal_compras/purchases/FETCH_BUDGET'
export const SET_BUDGET = 'portal_compras/purchases/SET_BUDGET'
export const DELETE_PROVIDER_PRODUCT = 'portal_compras/purchases/DELETE_PROVIDER_PRODUCT'
export const PUT_DEBOUNCED_CART = 'portal_compras/purchases/PUT_DEBOUNCED_CART'
export const SET_ERROR_CART = 'portal_compras/purchases/SET_ERROR_CART'
export const UPDATE_CART = 'portal_compras/purchases/UPDATE_CART'
export const MODAL_PRODUCT_OPEN = 'portal_compras/purchases/MODAL_PRODUCT_OPEN'
export const MODAL_ERROR_CART = 'portal_compras/purchases/MODAL_ERROR_CART'
export const IS_LOADING_CART = 'portal_compras/purchases/IS_LOADING_CART'
export const IS_LOADING_BUDGET = 'portal_compras/purchases/IS_LOADING_BUDGET'
export const ORIGIN_CENTERS = 'portal_compras/purchases/ORIGIN_CENTERS'
export const FETCH_ORIGIN_CENTERS = 'portal_compras/purchases/FETCH_ORIGIN_CENTERS'
export const SET_SELECTED_PROVIDER = 'portal_compras/purchases/SET_SELECTED_PROVIDER'
export const MODAL_PROVIDER_OPEN = 'portal_compras/purchases/MODAL_PROVIDER_OPEN'
export const SET_CART_DELIVERIES = 'portal_compras/purchases/SET_CART_DELIVERIES'
export const SET_ORIGIN_CENTER = 'portal_compras/purchases/SET_ORIGIN_CENTER'
export const SET_DATES_UPDATED = 'portal_compras/purchases/SET_DATES_UPDATED'
export const FETCH_ORDERS = 'portal_compras/purchases/FETCH_ORDERS'
export const IS_LOADING_ORDERS = 'portal_compras/purchases/IS_LOADING_ORDERS'
export const SET_ERROR_ORDERS = 'portal_compras/purchases/SET_ERROR_ORDERS'
export const FETCH_PROVIDER_ORDERS = 'portal_compras/purchases/FETCH_PROVIDER_ORDERS'
export const IS_LOADING_PROVIDER_ORDERS = 'portal_compras/purchases/IS_LOADING_PROVIDER_ORDERS'
export const SET_ERROR_PROVIDER_ORDERS = 'portal_compras/purchases/SET_ERROR_PROVIDER_ORDERS'
export const FETCH_HISTORIC_PROVIDERS = 'portal_compras/purchases/FETCH_HISTORIC_PROVIDERS'
export const FETCH_HISTORIC_CENTERS = 'portal_compras/purchases/FETCH_HISTORIC_CENTERS'
export const SET_HISTORIC_PROVIDERS = 'portal_compras/purchases/SET_HISTORIC_PROVIDERS'
export const SET_HISTORIC_CENTERS = 'portal_compras/purchases/SET_HISTORIC_CENTERS'
export const FETCH_REPEAT_ORDER = 'portal_compras/purchases/FETCH_REPEAT_ORDER'
export const IS_LOADING_REPEAT_ORDER = 'portal_compras/purchases/IS_LOADING_REPEAT_ORDER'
export const MODAL_ERROR_REPEAT_ORDER = 'portal_compras/purchases/MODAL_ERROR_REPEAT_ORDER'
export const FETCH_PENDING_PROVIDER_ORDERS = 'portal_compras/purchases/FETCH_PENDING_PROVIDER_ORDERS'
export const SET_PENDING_PROVIDER_ORDERS = 'portal_compras/purchases/SET_PENDING_PROVIDER_ORDERS'
export const SET_CATEGORIES_HABITUAL = 'portal_compras/purchases/SET_CATEGORIES_HABITUAL'
export const IS_LOADING_CATEGORIES_HABITUAL = 'portal_compras/purchases/IS_LOADING_CATEGORIES_HABITUAL'
export const ERROR_CATEGORIES_HABITUAL = 'portal_compras/purchases/ERROR_CATEGORIES_HABITUAL'
export const FETCH_CATEGORIES_HABITUAL = 'portal_compras/purchases/FETCH_CATEGORIES_HABITUAL'
export const SET_SELECTED_CATEGORIES_HABITUAL = 'portal_compras/purchases/SET_SELECTED_CATEGORIES_HABITUAL'
export const ERROR_HABITUAL_PRODUCTS = 'portal_compras/purchases/ERROR_HABITUAL_PRODUCTS'
export const FETCH_HABITUAL_PRODUCTS = 'portal_compras/purchases/FETCH_HABITUAL_PRODUCTS'
export const SET_HABITUAL_PRODUCTS = 'portal_compras/purchases/SET_HABITUAL_PRODUCTS'
export const SET_HABITUAL_PRODUCTS_LAST_REFERENCE = 'portal_compras/purchases/SET_HABITUAL_PRODUCTS_LAST_REFERENCE'
export const SET_HABITUAL_PRODUCTS_COUNT = 'portal_compras/purchases/SET_HABITUAL_PRODUCTS_COUNT'
export const IS_LOADING_HABITUAL_PRODUCTS = 'portal_compras/purchases/IS_LOADING_HABITUAL_PRODUCTS'
export const SET_SHOW_ONLY_ONE_PRODUCT = 'portal_compras/purchases/SET_SHOW_ONLY_ONE_PRODUCT'
export const SET_PREVIUS_ORDER_PRODUCTS = 'portal_compras/purchases/SET_PREVIUS_ORDER_PRODUCTS'
export const FETCH_PREVIUS_ORDER_PROVIDERS = 'portal_compras/purchases/FETCH_PREVIUS_ORDER_PROVIDERS'
export const IS_LOADING_PREVIUS_ORDER = 'portal_compras/purchases/IS_LOADING_PREVIUS_ORDER'
export const ERROR_PREVIUS_ORDER = 'portal_compras/purchases/ERROR_PREVIUS_ORDER'
export const FETCH_PREVIUS_ORDER_PRODUCTS = 'portal_compras/purchases/FETCH_PREVIUS_ORDER_PRODUCTS'
export const SET_PREVIUS_ORDER = 'portal_compras/purchases/SET_PREVIUS_ORDER'
export const SET_QA_FORM = 'portal_compras/purchases/SET_QA_FORM'
export const IS_UPDATING_CART = 'portal_compras/purchases/IS_UPDATING_CART'
export const REMOVE_REQUEST_FAILED = 'portal_compras/purchases/REMOVE_REQUEST_FAILED'
export const ADD_REQUEST_FAILED_TO_CART = 'portal_compras/purchases/ADD_REQUEST_FAILED_TO_CART'
export const SET_IS_LOADING_PRECONFIRM = 'portal_compras/purchases/SET_IS_LOADING_PRECONFIRM'
export const SET_ADDING_PRODUCT_QUEUE = 'portal_compras/purchases/SET_ADDING_PRODUCT_QUEUE'
export const CHECK_CONFIRMED_CART = 'portal_compras/purchases/CHECK_CONFIRMED_CART'
export const SET_CONFIRMED_CART = 'portal_compras/purchases/SET_CONFIRMED_CART'
export const SET_LOADING_IS_RELOADING = 'portal_compras/purchases/SET_LOADING_IS_RELOADING'
export const FETCH_IS_RELOADING = 'portal_compras/purchases/FETCH_IS_RELOADING'
export const SET_IS_RELOADING = 'portal_compras/purchases/SET_IS_RELOADING'
export const SET_ORDERS = 'portal_compras/purchases/SET_ORDERS'
export const SET_PROVIDER_ORDERS = 'portal_compras/purchases/SET_PROVIDER_ORDERS'
export const FETCH_FAILED_RECEPTIONS = 'portal_compras/purchases/FETCH_FAILED_RECEPTIONS'
export const SET_FAILED_RECEPTIONS = 'portal_compras/purchases/SET_FAILED_RECEPTIONS'
