import React, { useState, useRef, useCallback, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import uniqBy from 'lodash/uniqBy'
import range from 'lodash/range'
import ContentLoader from 'react-content-loader'
import { ProviderCard } from 'Components/atoms'
import { ProviderFilter } from 'Components/molecules'
import { FETCH_PROVIDERS } from 'Redux/purchases/types'
import { FILTER_ORDER } from 'Config/constants'
import useTutorialBanner from 'hooks/tutorial/useTutorialBanner'
import { needsApprovalMode } from 'Redux/approver/selectors'

import ModalProvider from '../modalProvider'

import { Title, CardsContainer, ProviderCardLoadingContainer, TopbarContainer } from './styled'

const LIMIT = 100

const Providers = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const observer = useRef()

  const [filtered, setFiltered] = useState(FILTER_ORDER.ALPHABETICALLY)
  const [pageNumber, setPageNumber] = useState(0)
  const [hasMore, setHasMore] = useState('')
  const [providers, setProviders] = useState([])

  const selectedCenter = useSelector(state => state.auth.selectedCenter)
  const providersReducer = useSelector(state => state.purchase.providers)
  const isLoading = useSelector(state => state.purchase.isLoadingProviders)

  const needsApproval = useSelector(needsApprovalMode)

  const { isTutorialBannerEnabled, isTutorialEnabled } = useTutorialBanner()

  const lastProductElementRef = useCallback(
    node => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber(prevPageNumber => prevPageNumber + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoading, hasMore]
  )

  useEffect(() => {
    const offset = pageNumber * LIMIT
    const params = { limit: LIMIT, offset }
    dispatch({ type: FETCH_PROVIDERS, params })
  }, [dispatch, selectedCenter, pageNumber])

  useEffect(() => {
    setProviders(prevProducts => uniqBy([...prevProducts, ...providersReducer], 'id'))
    setHasMore(providersReducer.length > 0 && providersReducer.length >= LIMIT)
  }, [providersReducer])

  const renderProviders = () => {
    let providersToShow = providers
    if (filtered === FILTER_ORDER.ALPHABETICALLY) {
      const clonedProviders = cloneDeep(providers)
      const arrangedProviders = clonedProviders.sort((a, b) => a.name?.localeCompare(b.name))
      providersToShow = arrangedProviders
    }

    if (filtered === FILTER_ORDER.DELIVERY) {
      const clonedProviders = cloneDeep(providers)
      const arrangedProviders = clonedProviders.sort((a, b) => {
        const noDateReturn = 10
        if (!a.nextDeliveryDate) return noDateReturn
        return a.nextDeliveryDate.localeCompare(b.nextDeliveryDate)
      })
      providersToShow = arrangedProviders
    }

    return providersToShow.map(({ id, name, nextDeliveryDate, calendar }, index) => (
      <ProviderCard
        key={id}
        id={id}
        name={name}
        calendar={calendar}
        nextDeliveryDate={nextDeliveryDate}
        refProp={providers.length === index + 1 ? lastProductElementRef : null}
      />
    ))
  }

  return (
    <>
      <TopbarContainer>
        <Title>{t('providers')}</Title>
        <div style={{ width: '13rem' }}>
          <ProviderFilter filtered={filtered} setFiltered={setFiltered} />
        </div>
      </TopbarContainer>
      <CardsContainer $tutorial={isTutorialBannerEnabled && isTutorialEnabled} $needsApproval={needsApproval}>
        {providers.length > 0 && renderProviders()}
        {isLoading && <MultipleProviderCardLoading />}
      </CardsContainer>
      <ModalProvider />
    </>
  )
}

const MultipleProviderCardLoading = () => (
  <>
    {range(8).map(i => (
      <ProviderCardLoadingContainer key={i}>
        <ProviderCardLoading key={i} />
      </ProviderCardLoadingContainer>
    ))}
  </>
)

const ProviderCardLoading = () => {
  const { t } = useTranslation()
  return (
    <ContentLoader
      speed={2}
      width={320}
      height={174}
      viewBox='0 0 320 174'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='16' y='16' rx='3' ry='3' width='288' height='10' />
      <rect x='16' y='60' rx='0' ry='0' width='288' height='6' />
      <rect x='16' y='80' rx='0' ry='0' width='150' height='6' />
      <rect x='16' y='119' rx='0' ry='0' width='288' height='38' />
    </ContentLoader>
  )
}

export default Providers
