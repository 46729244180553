import React from 'react'

import { useTranslation } from 'react-i18next'
import { ModalLoading } from 'Components/molecules'
import useShoppingLists from 'hooks/shoppingLists/useShoppingLists'
import useShoppingListsRemove from 'hooks/shoppingLists/useShoppingListRemove'
import useShoppingListsRemoveProduct from 'hooks/shoppingLists/useShoppingListRemoveProduct'
import useTutorialBanner from 'hooks/tutorial/useTutorialBanner'

import { Container, TitleContainer, TopbarContainer } from './styled'
import List from './widgets/list'
import ListLoader from './widgets/listLoader'
import RemoveListModal from './widgets/removeListModal'
import SingleModal from './widgets/singleModal'
import RemoveProductModal from './widgets/removeProductModal'

const ShoppingLists = () => {
  const { t } = useTranslation()
  const { isFetching, selectedList, isSendingCart, onSeeProducts } = useShoppingLists()
  const { removeListModal, onRemoveList, setRemoveListModal } = useShoppingListsRemove()
  const { removeProductModal, onRemoveProduct, setRemoveProductModal } = useShoppingListsRemoveProduct()

  const { isTutorialBannerEnabled, isTutorialEnabled } = useTutorialBanner()

  return (
    <>
      <Container $tutorial={isTutorialBannerEnabled && isTutorialEnabled}>
        <TopbarContainer>
          <TitleContainer>{t('shoppingList.list.title')}</TitleContainer>
        </TopbarContainer>
        {isFetching && <ListLoader />}
        {!isFetching && <List onClickRemove={setRemoveListModal} onClickSee={onSeeProducts} />}
        <RemoveListModal
          showModal={!!removeListModal}
          setShowModal={() => setRemoveListModal(null)}
          onAccept={onRemoveList}
        />
        <RemoveProductModal
          showModal={!!removeProductModal}
          setShowModal={() => setRemoveProductModal(null)}
          onAccept={onRemoveProduct}
        />
      </Container>
      {selectedList && <SingleModal onClickRemove={setRemoveListModal} onClickRemoveProduct={setRemoveProductModal} />}
      <ModalLoading showModal={isSendingCart} />
    </>
  )
}

export default ShoppingLists
