import React, { useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as FilterLeftIcon } from 'Assets/Icons/FilterLeft.svg'
import { useClickOutside } from 'Components/utils/customHooks'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { FILTER_ORDER } from 'Config/constants'

import { FilterButton, FilterContainer } from './styled'

const Filter = ({ filtered, setFiltered }) => {
  const [displayFilter, setDisplayFilter] = useState('none')
  const filterRef = useRef()
  const closeFilters = () => setDisplayFilter('none')
  useClickOutside(filterRef, closeFilters)
  const { t } = useTranslation()

  return (
    <div ref={filterRef} style={{ position: 'relative' }}>
      <FilterButton onClick={e => setDisplayFilter(e.target.value)}>
        <FilterLeftIcon className='icon' />
        {t('arrangeProviders')}
      </FilterButton>
      <FilterContainer display={displayFilter}>
        <RadioGroup value={filtered} onChange={e => setFiltered(e.target.value)}>
          <FormControlLabel
            value={FILTER_ORDER.ALPHABETICALLY}
            control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
            label={t('arrangeProvidersAlpha')}
            labelPlacement='start'
          />
          <FormControlLabel
            value={FILTER_ORDER.DELIVERY}
            control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
            label={t('arrangeProvidersDelivery')}
            labelPlacement='start'
          />
        </RadioGroup>
      </FilterContainer>
    </div>
  )
}

Filter.defaultProps = {
  filtered: '',
  setFiltered: () => {},
}

Filter.propTypes = {
  filtered: PropTypes.string,
  setFiltered: PropTypes.func,
}

export default Filter
