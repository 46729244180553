import { LOGOUT, SET_SELECTED_CENTER, LOGIN } from 'Redux/auth/types'

import {
  SEARCH_PURCHASES,
  ERROR_SEARCH,
  ERROR_PRODUCTS,
  SET_PRODUCTS,
  SET_PRODUCTS_COUNT,
  IS_LOADING_PRODUCTS,
  SET_CATEGORIES,
  SET_SELECTED_CATEGORIES,
  SET_PROVIDERS,
  SET_ERROR_PROVIDERS,
  IS_LOADING_PROVIDERS,
  IS_LOADING_CATEGORIES,
  SET_PROVIDER_CATEGORIES,
  EDIT_CART,
  SET_LOADING_PRODUCT,
  SET_PRODUCT,
  SET_CART,
  CART_INITIATED,
  SET_BUDGET,
  SET_ERROR_CART,
  MODAL_PRODUCT_OPEN,
  MODAL_ERROR_CART,
  IS_LOADING_CART,
  IS_LOADING_BUDGET,
  ORIGIN_CENTERS,
  SET_SELECTED_PROVIDER,
  MODAL_PROVIDER_OPEN,
  SET_CART_DELIVERIES,
  SET_ORIGIN_CENTER,
  CART_CONFIRMED,
  SET_DATES_UPDATED,
  IS_LOADING_ORDERS,
  SET_ERROR_ORDERS,
  IS_LOADING_PROVIDER_ORDERS,
  SET_ERROR_PROVIDER_ORDERS,
  SET_HISTORIC_PROVIDERS,
  SET_HISTORIC_CENTERS,
  IS_LOADING_REPEAT_ORDER,
  MODAL_ERROR_REPEAT_ORDER,
  SET_PENDING_PROVIDER_ORDERS,
  SET_CATEGORIES_HABITUAL,
  IS_LOADING_CATEGORIES_HABITUAL,
  ERROR_CATEGORIES_HABITUAL,
  SET_SELECTED_CATEGORIES_HABITUAL,
  IS_LOADING_HABITUAL_PRODUCTS,
  SET_HABITUAL_PRODUCTS_COUNT,
  SET_HABITUAL_PRODUCTS,
  ERROR_HABITUAL_PRODUCTS,
  SET_SHOW_ONLY_ONE_PRODUCT,
  SET_PREVIUS_ORDER_PRODUCTS,
  IS_LOADING_PREVIUS_ORDER,
  ERROR_PREVIUS_ORDER,
  SET_PREVIUS_ORDER,
  SET_QA_FORM,
  SET_HABITUAL_PRODUCTS_LAST_REFERENCE,
  IS_UPDATING_CART,
  SET_IS_EDITING_CART,
  SET_IS_LOADING_PRECONFIRM,
  ADD_PRODUCT_TO_CART,
  SET_ADDING_PRODUCT_QUEUE,
  SET_CONFIRMED_CART,
  SET_LOADING_IS_RELOADING,
  SET_IS_RELOADING,
  SET_ORDERS,
  SET_PROVIDER_ORDERS,
  SET_FAILED_RECEPTIONS,
} from './types'

const initialState = {
  mainSearch: [],
  errorSearch: '',
  errorProducts: '',
  products: [],
  productsCount: 0,
  selectedCategories: {},
  selectedCategoriesHabitual: {},
  isLoadingProducts: false,
  categories: [],
  providers: [],
  errorProviders: '',
  isLoadingProviders: false,
  isLoadingCategories: false,
  providerCategories: [],
  cart: {},
  deliveries: [],
  origin: false,
  isLoadingProduct: false,
  product: {},
  cartInitiated: false,
  budget: {},
  errorCart: false,
  modalProductOpen: false,
  isLoadingCart: false,
  showModalError: false,
  isLoadingBudget: false,
  originCenters: [],
  selectedProvider: {},
  modalProviderOpen: false,
  trackingId: false,
  datesUpdated: false,
  orders: [],
  isLoadingOrders: false,
  errorOrders: false,
  providerOrders: [],
  isLoadingProviderOrders: false,
  errorProviderOrders: false,
  historicProviders: [],
  historicCenters: [],
  isLoadingRepeatOrder: false,
  repeatOrderErrors: null,
  numProviderPendingOrders: 0,
  categoriesHabitual: [],
  isLoadingCategoriesHabitual: false,
  errorCategoryHabitual: false,
  errorHabitualProducts: '',
  habitualProducts: [],
  habitualProductsCount: 0,
  isLoadingHabitualProducts: false,
  showOnlyOneProduct: false,
  previusOrderProducts: [],
  isLoadingPreviusOrder: false,
  errorPreviusOrder: false,
  previusOrder: null,
  qaForm: {},
  lastReference: null,
  isUpdatingCart: false,
  isEditingCart: false,
  isLoadingPreConfirm: false,
  addProductsQueue: {},
  confirmedCart: {},
  isLoadingReloading: false,
  isReloading: {},
  failedReceptions: [],
}

const Reducer = (state = initialState, { type, payload, id }) => {
  switch (type) {
    case SEARCH_PURCHASES:
      return { ...state, mainSearch: payload }
    case ERROR_SEARCH:
      return { ...state, errorSearch: payload }
    case ERROR_PRODUCTS:
      return { ...state, errorProducts: payload }
    case SET_PRODUCTS:
      return { ...state, products: payload }
    case SET_PRODUCTS_COUNT:
      return { ...state, productsCount: payload }
    case SET_SELECTED_CATEGORIES:
      return { ...state, selectedCategories: payload }
    case IS_LOADING_PRODUCTS:
      return { ...state, isLoadingProducts: payload }
    case SET_CATEGORIES:
      return { ...state, categories: payload }
    case SET_PROVIDERS:
      return { ...state, providers: payload }
    case SET_ERROR_PROVIDERS:
      return { ...state, errorProviders: payload }
    case IS_LOADING_PROVIDERS:
      return { ...state, isLoadingProviders: payload }
    case IS_LOADING_CATEGORIES:
      return { ...state, isLoadingCategories: payload }
    case SET_PROVIDER_CATEGORIES:
      return { ...state, providerCategories: payload }
    case SET_LOADING_PRODUCT:
      return { ...state, isLoadingProduct: payload }
    case SET_PRODUCT:
      return { ...state, product: payload }
    case SET_ERROR_CART:
      return { ...state, errorCart: payload }
    case IS_LOADING_CART:
      return { ...state, isLoadingCart: payload }
    case IS_LOADING_BUDGET:
      return { ...state, isLoadingBudget: payload }
    case MODAL_PRODUCT_OPEN:
      return { ...state, modalProductOpen: payload }
    case MODAL_ERROR_CART:
      return { ...state, showModalError: payload }
    case ORIGIN_CENTERS:
      return { ...state, originCenters: payload }
    case SET_SELECTED_CENTER:
      return initialState
    case EDIT_CART:
      return { ...state, cart: { ...state.cart, [id]: payload } }
    case ADD_PRODUCT_TO_CART:
      return { ...state, cart: { ...state.cart, [id]: payload } }
    case SET_CART:
      return { ...state, cart: payload }
    case SET_CART_DELIVERIES:
      return { ...state, deliveries: payload }
    case SET_ORIGIN_CENTER:
      return { ...state, origin: payload }
    case CART_INITIATED:
      return { ...state, cartInitiated: payload }
    case SET_SELECTED_PROVIDER:
      return { ...state, selectedProvider: payload }
    case MODAL_PROVIDER_OPEN:
      return { ...state, modalProviderOpen: payload }
    case CART_CONFIRMED:
      return { ...state, ...payload }
    case SET_BUDGET:
      return { ...state, budget: payload }
    case SET_DATES_UPDATED:
      return { ...state, datesUpdated: payload }
    case SET_ORDERS:
      return { ...state, orders: payload }
    case SET_FAILED_RECEPTIONS:
      return { ...state, failedReceptions: payload }
    case IS_LOADING_ORDERS:
      return { ...state, isLoadingOrders: payload }
    case SET_ERROR_ORDERS:
      return { ...state, errorOrders: payload }
    case SET_PROVIDER_ORDERS:
      return { ...state, providerOrders: payload }
    case IS_LOADING_PROVIDER_ORDERS:
      return { ...state, isLoadingProviderOrders: payload }
    case SET_ERROR_PROVIDER_ORDERS:
      return { ...state, errorProviderOrders: payload }
    case SET_HISTORIC_PROVIDERS:
      return { ...state, historicProviders: payload }
    case SET_HISTORIC_CENTERS:
      return { ...state, historicCenters: payload }
    case IS_LOADING_REPEAT_ORDER:
      return { ...state, isLoadingRepeatOrder: payload }
    case MODAL_ERROR_REPEAT_ORDER:
      return { ...state, repeatOrderErrors: payload }
    case SET_PENDING_PROVIDER_ORDERS:
      return { ...state, numProviderPendingOrders: payload }
    case SET_CATEGORIES_HABITUAL:
      return { ...state, categoriesHabitual: payload }
    case IS_LOADING_CATEGORIES_HABITUAL:
      return { ...state, isLoadingCategoriesHabitual: payload }
    case ERROR_CATEGORIES_HABITUAL:
      return { ...state, errorCategoryHabitual: payload }
    case SET_SELECTED_CATEGORIES_HABITUAL:
      return { ...state, selectedCategoriesHabitual: payload }
    case ERROR_HABITUAL_PRODUCTS:
      return { ...state, errorHabitualProducts: payload }
    case SET_HABITUAL_PRODUCTS:
      return { ...state, habitualProducts: payload }
    case SET_HABITUAL_PRODUCTS_LAST_REFERENCE:
      return { ...state, lastReference: payload }
    case SET_HABITUAL_PRODUCTS_COUNT:
      return { ...state, habitualProductsCount: payload }
    case IS_LOADING_HABITUAL_PRODUCTS:
      return { ...state, isLoadingHabitualProducts: payload }
    case SET_SHOW_ONLY_ONE_PRODUCT:
      return { ...state, showOnlyOneProduct: payload }
    case SET_PREVIUS_ORDER_PRODUCTS:
      return { ...state, previusOrderProducts: payload }
    case IS_LOADING_PREVIUS_ORDER:
      return { ...state, isLoadingPreviusOrder: payload }
    case ERROR_PREVIUS_ORDER:
      return { ...state, errorPreviusOrder: payload }
    case SET_PREVIUS_ORDER:
      return { ...state, previusOrder: payload }
    case SET_QA_FORM:
      return { ...state, qaForm: payload }
    case LOGOUT:
      return initialState
    case LOGIN:
      return initialState
    case IS_UPDATING_CART:
      return { ...state, isUpdatingCart: payload }
    case SET_IS_EDITING_CART:
      return { ...state, isEditingCart: payload }
    case SET_IS_LOADING_PRECONFIRM:
      return { ...state, isLoadingPreConfirm: payload }
    case SET_ADDING_PRODUCT_QUEUE:
      // eslint-disable-next-line no-case-declarations
      const addProductsQueue = { ...state.addProductsQueue }
      if (payload) {
        addProductsQueue[id] = payload
      } else if (addProductsQueue[id]) {
        delete addProductsQueue[id]
      }

      return { ...state, addProductsQueue }
    case SET_CONFIRMED_CART:
      return { ...state, confirmedCart: payload }

    case SET_LOADING_IS_RELOADING:
      return { ...state, isLoadingReloading: payload }

    case SET_IS_RELOADING:
      return { ...state, isReloading: payload }
    default:
      return state
  }
}

export default Reducer
